import React from 'react'

import rollappMini from '../../../assets/img/logo.svg'
import { Banner } from '../../../ui/components/Banner'
import rollappAlt from '../assets/rollapp-logo.svg'
import { ExploreA } from '../styled'
import {
  HeaderButtons,
  HeaderInner,
  HeaderLink,
  HeaderWrapper,
  LinksBlock,
  LinksBox,
  LogoLink
} from './styled'

export const LandingHeader = (): JSX.Element => {
  return (
    <HeaderWrapper>
      <Banner />
      <HeaderInner>
        <LogoLink to="/">
          <img src={rollappAlt} />
          <img src={rollappMini} />
        </LogoLink>
        <LinksBlock>
          <LinksBox>
            <HeaderLink
              href="https://investcurio.medium.com/list/rollapp-nft-launchpad-1e8a84e5b6bf"
              target="_blank"
            >
              Blog
            </HeaderLink>
            <HeaderLink href="https://curioinvest.com/investor-relations" target="_blank">
              Invest in us
            </HeaderLink>
          </LinksBox>
          <HeaderButtons>
            <ExploreA
              href="https://meetings.hubspot.com/fernando-verboonen"
              className="landing-button landing-header-button"
              target="_blank"
            >
              Request a demo
            </ExploreA>
          </HeaderButtons>
        </LinksBlock>
      </HeaderInner>
    </HeaderWrapper>
  )
}
