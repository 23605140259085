import React from 'react'

import { Banner } from '../../components/Banner'
import { HeaderInner } from './HeaderInner'
import { Wrapper } from './styles'

export const Header = (): JSX.Element => {
  return (
    <Wrapper>
      <Banner />
      <HeaderInner />
    </Wrapper>
  )
}
