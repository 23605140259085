import styled from 'styled-components'

export const Wrapper = styled.div`
  background-color: var(--blue);
  color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px;
  gap: 14px;
  flex-direction: column;

  img {
    width: 24px;
  }
`

export const StyledLink = styled.a`
  &:hover {
    text-decoration: underline;
  }
`
